import { ISettingsStoreType, MapType, UserThemeSettings } from 'types';
import createApiAction from 'api/createApiAction';
import * as c from 'constants/actions';


export const getUserSettings = () =>
  createApiAction({
    method: 'getUserSettings',
    requestAction: c.GET_USER_SETTINGS_REQUEST,
    successAction: c.GET_USER_SETTINGS_SUCCESS,
    errorAction: c.GET_USER_SETTINGS_ERROR,
    transformResponse: (data: { map?: MapType, volume?: number, settings: UserThemeSettings } | null) => {
      if (!data) {
        return {}
      }
      const { map, volume, settings } = data;
      return { map, volume, settings };
    },
  });


export const handleSoundVolume = (soundVolume: ISettingsStoreType['soundVolume']) =>
  createApiAction({
    method: 'updateUserSettings',
    params: { volume: soundVolume * 100 },
    requestPayload: { soundVolume },
    requestAction: c.SAVE_SOUND_VOLUME,
    successAction: c.SAVE_SOUND_VOLUME_SUCCESS,
    errorAction: c.SAVE_SOUND_VOLUME_ERROR,
  });

export const toggleEnableGoogleMap = (isGoogleMapEnabled: ISettingsStoreType['isGoogleMapEnabled']) =>
  createApiAction({
    method: 'updateUserSettings',
    params: { map: isGoogleMapEnabled ? MapType.GOOGLE: MapType.YANDEX },
    requestPayload: { isGoogleMapEnabled },
    requestAction: c.TOGGLE_GOOGLE_MAP,
    successAction: c.TOGGLE_GOOGLE_MAP_SUCCESS,
    errorAction: c.TOGGLE_GOOGLE_MAP_ERROR,
  });

export const toggleDisableImages = (isImageEnabled: ISettingsStoreType['isImageEnabled']) =>
  createApiAction({
    method: 'updateUserSettings',
    params: { settings: { isImageEnabled: isImageEnabled } },
    requestPayload: { isImageEnabled },
    requestAction: c.TOGGLE_DISABLE_IMAGES,
    successAction: c.TOGGLE_DISABLE_IMAGES_SUCCESS,
    errorAction: c.TOGGLE_DISABLE_IMAGES_ERROR,
  });

export const setSocketReadyState = (payload: ISettingsStoreType['socketReadyState']) => {
  return {
    type: c.SET_SOCKET_READY_STATE,
    payload,
  };
};