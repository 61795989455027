import React, { useEffect, useMemo, useState } from 'react';
import { Box, Dialog, makeStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { ReactComponent as Arrow } from '../../../assets/arrow-right.svg';

const useStyles = makeStyles({
  paper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    overflow: 'hidden',
    position: 'relative',
    '& img': {
      maxHeight: '100%',
      maxWidth: 'inherit',
    },
  },
  actionBtn: {
    cursor: 'pointer',
    position: 'absolute',
    height: 40,
    width: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(47,48,48, 0.9)',
  },
});

interface Props {
  src?: string;
  images?: Array<string>;
  toggle: Function;
}

const DialogWithImage = ({ src, images = [], toggle }: Props) => {
  const [imageIndex, handleImagIndex] = useState(0);

  const { paper, actionBtn } = useStyles();

  const next = () => {
    handleImagIndex((state) => (state < images.length - 1 ? state + 1 : state));
  };

  const prev = () => {
    handleImagIndex((state) => (state > 0 ? state - 1 : state));
  };

  const handleKeyPress = (e: any) => {
    if (e.keyCode === 37) {
      prev();
    } else if (e.keyCode === 39) {
      next();
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', handleKeyPress, false);
    return () => {
      window.removeEventListener('keyup', handleKeyPress, false);
    };
  }, []);

  const img = useMemo((): string => {
    if (src) {
      return src;
    }
    return images[imageIndex];
  }, [images, src, imageIndex]);

  return (
    <Dialog open onClose={() => toggle()} maxWidth="md" classes={{ paper }}>
      <Box onClick={() => toggle()} className={actionBtn} right="0" top="0">
        <Close />
      </Box>
      {imageIndex > 0 && (
        <Box onClick={prev} className={actionBtn} left="0">
          <Arrow fill="white" style={{ transform: 'rotate(180deg)' }} />
        </Box>
      )}
      <img src={img} alt="full-screen-img" />
      {imageIndex < images.length - 1 && (
        <Box onClick={next} className={actionBtn} right="0">
          <Arrow fill="white" />
        </Box>
      )}
    </Dialog>
  );
};

export default DialogWithImage;
