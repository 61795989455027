import React from 'react';
import {
  Button,
  Dialog,
  Box,
  Typography,
  IconButton,
  CircularProgress,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Field, Formik } from 'formik';
import moment from 'moment';

import { CustomTextField, CustomDatePicker, FileUpload, CustomSelect } from 'components/input';
import { addAgentValidations } from 'utils/validation';
import useStyles from './styles';
import { IAppState } from 'types';
import { handleIsOpenModal } from 'actions/modal';
import { createAgentRequest } from 'actions/agent';
import { agent, operator } from 'constants/roles';

const AddAgentModal = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    location: { pathname },
  } = useHistory();

  const { sendBtn, container, title, label, focused, closeIcon, actionBtn, cancelBtn, triggerModal } = useStyles();

  const currentModal = useSelector(({ modal: { type } }: IAppState) => type === 'addAgent');

  const isModalOpen = useSelector(({ modal }: IAppState) => modal.isOpen);

  const isSubmitting = useSelector(({ family }: IAppState) => family.isSubmitting);
  const providers = useSelector((state: IAppState) => state.auth.data.providers
    .map((provider) => ({ label: provider.name, value: provider.id })));

  const rolesOptions = React.useMemo(
    () => [agent, operator]
      .map((role) => ({
        label: <FormattedMessage id={`panic_users.${role}`} defaultMessage={role} />,
        value: role,
      })),
    []
  );

  const handleModalClose = () => {
    dispatch(handleIsOpenModal({ type: null, isOpen: false }));
  };

  const handleOpen = () => {
    dispatch(handleIsOpenModal({ type: 'addAgent', isOpen: true }));
  };

  const open = currentModal && isModalOpen && pathname === '/agents';

  return (
    <>
      <Box>
        <Box m={2}>
          {!matches ? (
            <Button onClick={handleOpen} variant="outlined" size="small">
              <FormattedMessage id="agent.addAgent" defaultMessage="Add Agent" />
            </Button>
          ) : (
            <Box
              onClick={handleOpen}
              className={triggerModal}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="subtitle1">+</Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Dialog
        open={open}
        maxWidth="sm"
        fullWidth
        onClose={handleModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <IconButton size="small" className={closeIcon} onClick={handleModalClose}>
          <Close style={{ fill: '#6A6A6A' }} />
        </IconButton>
        <Box
          padding="30px 24px 24px"
          height="100%"
          width="100%"
          display="flex"
          flexDirection="column"
          className={container}
        >
          <Typography variant="h4" className={title}>
            <FormattedMessage id="agent.addAgent" />
          </Typography>
          <Formik
            initialValues={{
              phone: '',
              firstName: '',
              lastName: '',
              midName: undefined,
              // weight: 0,
              // height: 0,
              weight: undefined,
              height: undefined,
              birthdate: undefined,
              // birthdate: '1980-05-24',
              /**
               * @TODO:
               * If after user creation, we need to check if user have already and photo
               * If it does this will be ignored
               * Else we will need to request upload link from
               * POST v1/providers/:providerId/users/:userId/photo {mimeType: string}
               */
              // RE-TODO: Added new method and action, uncommented afterSuccess code
              photoId: undefined,
              photo: undefined,
              role: 'agent',
              // RE-TODO: Added select field for providers, need list of providers to fill it
              providerId: undefined,
            }}
            validationSchema={addAgentValidations}
            onSubmit={(values) => {
              dispatch(
                createAgentRequest({
                  params: {
                    ...values,
                    providerId: Number(values.providerId || 0),
                    ...(values.phone && {
                      phone: values.phone.replace(/\s/g, ''),
                    }),
                  },
                  cb: handleModalClose,
                })
              );
            }}
          >
            {({ handleSubmit, values, setFieldValue, touched, errors, setFieldError, setFieldTouched, ...props }) => (
              <form onSubmit={handleSubmit} noValidate>
                <Field
                  id="firstName"
                  name="firstName"
                  label={<FormattedMessage id="common.name" defaultMessage="First Name" />}
                  type="text"
                  component={CustomTextField}
                  InputLabelProps={{
                    classes: { root: label, focused },
                  }}
                  margin="dense"
                  required
                />
                <Field
                  id="midName"
                  name="midName"
                  label={<FormattedMessage id="common.midname" defaultMessage="Mid Name" />}
                  type="text"
                  component={CustomTextField}
                  InputLabelProps={{
                    classes: { root: label, focused },
                  }}
                  margin="dense"
                />
                <Field
                  id="lastName"
                  name="lastName"
                  label={<FormattedMessage id="common.surname" defaultMessage="Last Name" />}
                  type="text"
                  component={CustomTextField}
                  InputLabelProps={{
                    classes: { root: label, focused },
                  }}
                  margin="dense"
                  required
                />
                <CustomDatePicker
                  name="birthdate"
                  label={<FormattedMessage id="common.birthday" defaultMessage="Birth Date" />}
                  setFieldValue={setFieldValue}
                  value={values.birthdate}
                  error={false}
                  helperText=""
                  maxDate={moment().subtract(18, 'years')}
                  {...props}
                />
                <Field
                  name="role"
                  label={<FormattedMessage id="common.role" defaultMessage="role" />}
                  component={CustomSelect}
                  options={rolesOptions}
                  margin="dense"
                  required
                />
                <Field
                  name="providerId"
                  label={<FormattedMessage id="common.provider" defaultMessage="provider" />}
                  component={CustomSelect}
                  options={providers}
                  margin="dense"
                  required
                />
                <FileUpload
                  name="photo"
                  setFieldValue={setFieldValue}
                  helperText={touched.photoId ? errors.photoId : ''}
                  value={values.photo}
                  setFieldError={setFieldError}
                  setFieldTouched={setFieldTouched}
                />
                <Field
                  id="phone"
                  name="phone"
                  label={<FormattedMessage id="common.phone" defaultMessage="Phone Number" />}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  InputLabelProps={{
                    classes: { root: label, focused },
                  }}
                  type="text"
                  margin="dense"
                  component={CustomTextField}
                  required
                />
                <Field
                  id="height"
                  name="height"
                  label={<FormattedMessage id="common.height" defaultMessage="height" />}
                  type="number"
                  component={CustomTextField}
                  InputLabelProps={{
                    classes: { root: label, focused },
                  }}
                  margin="dense"
                />
                <Field
                  id="weight"
                  name="weight"
                  label={<FormattedMessage id="common.weight" defaultMessage="weight" />}
                  type="number"
                  component={CustomTextField}
                  InputLabelProps={{
                    classes: { root: label, focused },
                  }}
                  margin="dense"
                />
                <Button variant="outlined" onClick={handleModalClose} className={`${actionBtn} ${cancelBtn}`}>
                  <FormattedMessage id="common.cancel" defaultMessage="Send" />
                </Button>
                <Button variant="outlined" type="submit" className={`${actionBtn} ${sendBtn}`}>
                  {isSubmitting ? (
                    <CircularProgress size={21} color="inherit" />
                  ) : (
                    <FormattedMessage id="common.sendBtn" defaultMessage="Send" />
                  )}
                </Button>
              </form>
            )}
          </Formik>
        </Box>
      </Dialog>
    </>
  );
};

export default AddAgentModal;
