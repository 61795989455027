import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import withAuth from '../hoc/withAuth';
import MainLayout from './main-layout';

interface OwnProps {
  path: string | string[];
  exact?: boolean;
  render: (any: any) => JSX.Element;
  auth: {
    isLoading: boolean;
    isAuthenticated: boolean;
    data: any;
  };
}

const MainLayoutRoute = ({ render, auth: { isLoading, isAuthenticated }, ...rest }: OwnProps) => {
  const authRoute = (matchProps: any) => <MainLayout>{render(matchProps)}</MainLayout>;
  return (
    <Route
      {...rest}
      render={(matchProps) => !isLoading && (isAuthenticated ? authRoute(matchProps) : <Redirect to="/login" />)}
    />
  );
};

export default withAuth(MainLayoutRoute);
