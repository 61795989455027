import * as c from '../constants/actions';
import { toIndexedMap, createReducer } from '../utils';
// import { IFamilyStoreType, FamilyDetailsType } from 'types';

// todo - rename from family to agent

const initialState = {
  byId: {},
  allIds: [],
  isLoading: false,
  isSubmitting: false,
  availableIds: [],
  agentsLocation: {},
  isLoaded: false,
  error: null,
  totalItems: -1,
};

export default createReducer(initialState, {
  [c.GET_FAMILY_LIST_REQUEST]: (state) => ({
    ...state,
    error: null,
    isLoading: true,
    isLoaded: false,
  }),
  [c.GET_FAMILY_LIST_SUCCESS]: (state, { payload }) => ({
    ...state,
    byId: { ...state.byId, ...toIndexedMap(payload.items) },
    allIds: payload.items.map((el) => el.id),
    totalItems: payload.totalItems,
    isLoading: false,
    isLoaded: true,
  }),
  [c.GET_FAMILY_LIST_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.ADD_FAMILY_REQUEST]: (state) => ({
    ...state,
    error: null,
    isSubmitting: true,
  }),
  [c.ADD_FAMILY_SUCCESS]: (state, { payload }) => ({
    ...state,
    byId: { ...state.byId, [payload.id]: payload },
    allIds: [...state.allIds, payload.id],
    isSubmitting: false,
  }),
  [c.ADD_FAMILY_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isSubmitting: false,
  }),
  [c.EDIT_FAMILY_REQUEST]: (state) => ({
    ...state,
    error: null,
    isSubmitting: true,
  }),
  [c.EDIT_FAMILY_SUCCESS]: (state, { payload }) => ({
    ...state,
    byId: { ...state.byId, [payload.id]: payload },
    isSubmitting: false,
  }),
  [c.EDIT_FAMILY_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isSubmitting: false,
  }),
  [c.UPLOAD_AGENT_PHOTO_REQUEST]: (state) => ({
    ...state,
    error: null,
    isSubmitting: true,
  }),
  [c.UPLOAD_AGENT_PHOTO_SUCCESS]: (state) => ({
    ...state,
    isSubmitting: false,
  }),
  [c.UPLOAD_AGENT_PHOTO_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isSubmitting: false,
  }),

  [c.DELETE_FAMILY_REQUEST]: (state) => ({
    ...state,
    error: null,
  }),
  [c.DELETE_FAMILY_SUCCESS]: (state, { payload }) => ({
    ...state,
    allIds: state.allIds.filter((el) => el !== payload),
  }),
  [c.DELETE_FAMILY_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
  }),
  [c.UPDATE_AGENT_COORDS]: (state, { payload }) => {
    return {
      ...state,
      agentsLocation: { ...state.agentsLocation, [payload.agentId]: { ...state.agentsLocation[payload.agentId], ...payload } },
    }},
  [c.GET_AGENT_LOCATIONS_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      agentsLocation: { ...state.agentsLocation, ...payload },
    }},
  [c.GET_AVAILABLE_AGENTS_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      availableIds: payload,
    }},
  [c.SET_AGENT_AVAILABLE]: (state, { payload }) => {
    const updatedIds = state.availableIds.indexOf(payload) === -1 ? [...state.availableIds, payload] : state.availableIds
    return {
      ...state,
      availableIds: updatedIds,
    }},
  [c.SET_AGENT_BUSY]: (state, { payload }) => {
    const filteredIds = state.availableIds.filter((id) => id !== payload)
    return {
      ...state,
      availableIds: filteredIds,
    }},
});
