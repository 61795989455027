import { PanicStatus } from 'types';

/**
 * max speed in milliseconds of the user & agent path draw speed for completed panics
 */
export const pathDrawSpeed = 1000; // 1 second

/**
 * used to identify the info window where the close button should be hidden
 */
export const infoWindowAriaLabel = 'route-history-created-at-info';

export const strokeColorMap = {
  user: '#f44336',
  agent: '#2196f3',
}

export const clientMarkerColors: Record<PanicStatus, string> = {
  assigned: 'orange',
  deassigned: 'red',
  pending: 'red',
  cancel_request: '#FAE24C',
  processed: 'yellow',
  accepted: 'lightgreen',
  accepted_operator: 'lightgreen',
  inplace: 'blue',
  extended: 'blue',
  done: 'gray',
  canceled: 'gray',
};
