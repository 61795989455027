const fieldsToObj = (arr) =>
  arr.reduce(
    (acc, { name, value }) => ({
      ...acc,
      [name]: value !== undefined ? value : '',
    }),
    {}
  );

export default fieldsToObj;
