import { makeStyles } from '@material-ui/core/styles';
import { white } from '../../constants/colors';

const useStyles = makeStyles({
  indicator: {
    right: 'auto',
    backgroundColor: white,
  },
  logo: {},
  logoLink: {
    margin: '32px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tabStyle: {
    minHeight: 56,
    textTransform: 'none',
    padding: 0,
    '& > span': {
      flexDirection: 'row',
      justifyContent: 'start',
      padding: '0 17px 0 28px',
      '& span': {
        marginLeft: 18,
      },
    },
  },
  langSelect: {
    padding: '8px 18px 20px 20px',
  },
  logoutStyles: {
    display: 'flex',
    gap: 10,
    padding: '0 17px 0 28px',
    alignItems: 'center',
    minHeight: 56,
    opacity: 0.7,
    cursor: 'pointer'
  },
});

export default useStyles;
