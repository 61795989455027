import { LS_TOKEN_KEY } from '../constants';

/* eslint-disable consistent-return */
const jwtDecode = (token) => {
  try {
    const userData = token.split('.')[1];
    return JSON.parse(atob(userData));
  } catch (err) {
    localStorage.removeItem(LS_TOKEN_KEY);
  }
};
/* eslint-enable consistent-return */

export default jwtDecode;
