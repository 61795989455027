import { makeStyles } from '@material-ui/core/styles';
import { darkPaper } from '../constants/colors';

const useSnackbarStyles = makeStyles({
  root: {
    '& button, a': {
      color: '#fff',
      border: '2px solid white',
      borderRadius: '36px',
      transition: 'all 0.3s ease',
      padding: '4px 8px',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      margin: '0 5px',
    },
  },
  variantError: {
    color: 'white',
    backgroundColor: '#D32F2F',
  },
  variantSuccess: {
    color: 'white',
    backgroundColor: '#066056',
  },
  variantWarning: {
    color: darkPaper,
    backgroundColor: '#F4BA09',
  },
  variantInfo: {
    color: 'white',
    backgroundColor: '#2690E0',
  },
});

export default useSnackbarStyles;
