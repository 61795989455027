import { useEffect, useState } from 'react';


import api from '../api';

// image has finished loading and the blob url is ready to be reused
const FILE_URL_CACHE = new Map<string, string>();

// image is being loaded and the promise is pending to avoid initiating another request for the same image
const LOADING_IMAGE_QUEUE = new Map<string, Promise<any>>();


const getFile = async (url: string, signal?: AbortSignal) => {
  try {
    const file = await api.request('GET', url, {}, {}, { signal: signal, responseType: 'blob' });
    return file;
  } catch (e) {
    return '';
  }
};

export const useFile = <T>(url: string | undefined, updateIf: T[] = [], signal?: AbortSignal) => {
  const [file, handleFile] = useState('');

  useEffect(() => {
    const isValidUrl = url ? ['undefined', 'null'].every((el) => !url.includes(el)) : false;
    if (isValidUrl && url) {

      if (FILE_URL_CACHE.has(url)) {
        // means the image has already been loaded and the blob url is ready to be reused
        const result = FILE_URL_CACHE.get(url)
        result && handleFile(result);
      } else if (LOADING_IMAGE_QUEUE.has(url)) {
        // means the image is being loaded and the promise is pending to avoid initiating another request for the same image even if it's called from another component
        LOADING_IMAGE_QUEUE.get(url)?.then((data) => {
          handleFile(data);
        });
      } else {
        // the image is requested for the first time during this session and is not cached or loaded yet
        const promise = new Promise<string>((resolve, reject) => {
          getFile(url, signal).then((data) => {
            if (data) {
              const result = URL.createObjectURL(data);
              FILE_URL_CACHE.set(url, result);
              handleFile(result);
              resolve(result);
            }
          }).catch((error) => {
            reject(error);
          }).finally(() => {
            // image has finished loading or aborted, this doesn't indicate if the blob was cached or not
            LOADING_IMAGE_QUEUE.delete(url);
          });
        });
        LOADING_IMAGE_QUEUE.set(url, promise);
      }
    }
  }, [url, ...updateIf]);

  return file;
};

const getFileRaw = async (url: string) => {
  try {
    const file = await api.requestRaw('GET', url, {}, {}, { responseType: 'blob' });

    return file;
  } catch (e) {
    return null;
  }
};

export const useFileObject = (url?: string) => {
  const [file, setFile] = useState<File | undefined>();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!url || ['undefined', 'null'].some((el) => url.includes(el))) {
      setLoaded(true);
      return;
    }

    getFileRaw(url)
      .then((response) => {
        if (!response?.data) {
          return;
        }

        const contentType = response.headers['content-type'];

        setFile(response?.data && new File(
          [response.data as BlobPart],
          `agent-photo.${contentType.split('/').pop()}`,
          { type: contentType }
        ));
      })
      .finally(() => setLoaded(true));
  }, [url]);

  return { content: file, loaded };
};

export default getFile;
