import { makeStyles } from '@material-ui/core';
import { white } from '../../constants/colors';

const useStyles = makeStyles({
  image: {
    height: 40,
    width: 40,
    borderRadius: '50%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    WebkitBackgroundSize: 'contain',
    border: `1px solid ${white}`,
    marginTop: 16,
  },
});

export default useStyles;
