import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import 'typeface-roboto';
import { init, replayIntegration } from '@sentry/browser';
import * as serviceWorker from './serviceWorker';
import store from './store';
import App from './app';
import ErrorBoundary from './error-boundary';
import { QUERY_PARAMS_KEY } from 'constants/index';

init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    replayIntegration(),
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment: process.env.NODE_ENV || 'development',

});

if (window && window.location.search) {
  window.localStorage.setItem(QUERY_PARAMS_KEY, window.location.search);
}

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container!);
root.render(
  //@ts-expect-error need to update react-redux version
  <Provider store={store}>
    <ErrorBoundary>
      <App style={{ fontFamily: "'Roboto', sans-serif" }} />
    </ErrorBoundary>
  </Provider>,
);

serviceWorker.unregister();
