import React from 'react';
import PanicMiniMap from '../map-vis-gl/maps/panic-mini-map';
import { makeStyles } from '@material-ui/core';
import { infoWindowAriaLabel } from 'constants/map-settings';

type Props = {
  panicId: number;
}


export const styles = makeStyles({
  miniMapContainer: {
    // 64px = top bar height, 16px = DialogContent padding
    height: 'calc(100vh - 64px - 16px)',
    color: 'initial',
    [`& [aria-label="${infoWindowAriaLabel}"]`]: {
      '& button': {
        visibility: 'hidden',
        maxHeight: 0,

      }
    }
  },
});

const MiniMapContainer = ({ panicId }: Props) => {
  const classes = styles();
  return (
    <div className={classes.miniMapContainer} >
      <PanicMiniMap panicId={panicId} />
    </div>
  )
}



export default MiniMapContainer;
