import React from 'react';
import { AppBar, IconButton, Toolbar, Box, useTheme, useMediaQuery } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import LanguageSelect from '../language-select';
import { logoutRequest } from 'actions/auth';
import useStyles from './styles';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import MenuIcon from 'assets/new-menu.svg';
import AddAgentModal from '../modal/add-agent';
import { ExportPanicsData } from '../modal';
import Title from './title';
import { IAppState } from 'types';

type Props = {
  handleDrawerToggle: Function;
};

const TopAppBar = ({ handleDrawerToggle }: Props) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const formattedPathname = pathname?.replace(/[/]/, '');
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const socketReadyState = useSelector(({ settings }: IAppState) => settings.socketReadyState);

  const { appBar, menuButton, toolBar, websocketIndicator } = useStyles({ socketReadyState });


  const handleActiveBtns = (path: string) => {
    if (path === 'agents') {
      return [<AddAgentModal key="1" />];
    }
    if (path === 'history') {
      return [<ExportPanicsData key="1" />];
    }
    return null;
  };

  return (
    <AppBar position="fixed" color="default" className={appBar}>
      <Toolbar className={toolBar}>
        <IconButton
          color="secondary"
          aria-label="Open drawer"
          onClick={() => handleDrawerToggle()}
          className={menuButton}
        >
          <img src={MenuIcon} alt="menu-icon"  width={24} height={24}/>
        </IconButton>
        <Title />
        <Box display="flex" alignItems="center" justifyContent="flex-end" width="100%">
          <>
            {handleActiveBtns(formattedPathname)}
            <div className={websocketIndicator}/>
            {!matches && (
              <>
                <LanguageSelect />
                <IconButton onClick={() => dispatch(logoutRequest())}>
                  <SystemUpdateAltIcon style={{ transform: 'rotate(-90deg)' }}/>
                </IconButton>
              </>
            )}
          </>
        </Box>
      </Toolbar>
    </AppBar>
  );
};



export default TopAppBar;
