import React from 'react';
import { Dialog } from '@material-ui/core';
import useStyles from './styles';

type Props = {
  open: boolean;
  toggleModal: Function;
  imgUrl?: string;
  fullWidth?: boolean;
};

const QrModal = ({ open, toggleModal, imgUrl, /* fullWidth = false */ }: Props) => {
  const classes = useStyles();
  return (
    <Dialog
      fullWidth={false}
      maxWidth={false}
      open={open}
      onClose={() => {
        toggleModal();
      }}
    >
      <img src={imgUrl} alt="qr-img" className={classes.image} />
    </Dialog>
  );
};

export default QrModal;
