import React from 'react';
import { FormattedMessage } from 'react-intl';

import * as c from '../constants/actions';
import api from '../api';
import createApiAction from '../api/createApiAction';
import { CURRENT_USER_LOCALSTORAGE_KEY, LS_TOKEN_KEY } from '../constants';
import history from '../utils/history';
import { enqueueSnackbar } from './snackbar';
import { CLOSE_2FA_MODAL } from 'constants/actions';
import { getUserSettings } from 'actions/settings';

const saveToken = (token) => {
  // if (typeof window !== "undefined") {
  localStorage.setItem(LS_TOKEN_KEY, token);
  api.setJwtToken(token);
  // }
};

export const updateUserInfoRequest = (params) =>
  createApiAction({
    method: 'updateUserInfo',
    params,
    requestAction: c.UPDATE_USER_INFO_REQUEST,
    successAction: c.UPDATE_USER_INFO_SUCCESS,
    errorAction: c.UPDATE_USER_INFO_ERROR,
    transformResponse: ({ data }) => data,
  });

export const logoutRequest = () => {
  window.localStorage.removeItem(LS_TOKEN_KEY);
  api.setJwtToken(null);
  return { type: c.LOGOUT_SUCCESS };
};

export const getUserInfoRequest = () =>
  createApiAction({
    method: 'getUserInfo',
    requestAction: c.GET_USER_INFO_REQUEST,
    successAction: c.GET_USER_INFO_SUCCESS,
    errorAction: c.GET_USER_INFO_ERROR,
    afterSuccess: (dispatch, data) => {
      if (!data) {
        localStorage.removeItem(CURRENT_USER_LOCALSTORAGE_KEY);
        dispatch(logoutRequest());
      }
    },
  });

export const confirm2FaRequest = (params) =>
  createApiAction({
    method: 'confirm2fa',
    requestAction: c.CONFIRM_2FA_REQUEST,
    successAction: c.CONFIRM_2FA_SUCCESS,
    params,
    errorHandler(dispatch, { response }) {
      dispatch(
        enqueueSnackbar({
          message: response.data.message,
          options: {
            variant: 'error',
          },
        })
      );
      dispatch({
        type: c.CONFIRM_2FA_ERROR,
        payload: response.data.message,
      });
    },
    transformResponse: ({ data }) => data,
    afterSuccess: (dispatch, { data }) => {
      dispatch({
        type: CLOSE_2FA_MODAL,
      });
      saveToken(data.tokenData.accessToken);
      dispatch(getUserInfoRequest());
    },
  });

export const loginRequest = (params) =>
  createApiAction({
    method: 'login',
    requestAction: c.LOGIN_REQUEST,
    successAction: c.LOGIN_SUCCESS,
    params,
    errorHandler(dispatch, { response }) {
      dispatch(
        enqueueSnackbar({
          message: response.data.message,
          options: {
            variant: 'error',
          },
        })
      );
      dispatch({
        type: c.LOGIN_ERROR,
        payload: response.data.message,
      });
    },
    transformResponse: ({ data }) => data,
    afterSuccess: async (dispatch, { data }) => {
      if (data['2fa']) {
        dispatch({
          type: c.SHOW_2FA_MODAL,
        });
      } else {
        saveToken(data.tokenData.accessToken);
        dispatch(getUserInfoRequest());
        dispatch(getUserSettings());
      }
    },
  });

// @TODO: Remove this action
export const registerRequest = (params) =>
  createApiAction({
    method: 'register',
    requestAction: c.REGISTER_REQUEST,
    successAction: c.REGISTER_SUCCESS,
    params,
    errorHandler(dispatch, { response }) {
      dispatch(
        enqueueSnackbar({
          message: response.data.message,
          options: {
            variant: 'error',
          },
        })
      );
      dispatch({
        type: c.REGISTER_ERROR,
        payload: response.data.message,
      });
    },
    transformResponse: ({ data }) => data,
    afterSuccess: (dispatch) => {
      dispatch(
        enqueueSnackbar({
          message: (
            <FormattedMessage
              id="register.success"
              defaultMessage="Registration was Successfull. Check your phone for the password"
            />
          ),
          options: {
            variant: 'success',
          },
        })
      );
      const query = params.phone.slice(3);
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set('phone', query);
      history.push(`/login?${urlParams.toString()}`);
    },
  });

/**
 * Sends a request to reset password using phone or email.
 *
 * @param {object} params - The parameters for the request. Either `phone` or `email` should be provided, but not both.
 * @param {string} [params.phone] - The phone number of the user. This should not be provided if `email` is provided.
 * @param {string} [params.email] - The email address of the user. This should not be provided if `phone` is provided.
 * @param {Function} cb - The callback function to be executed after the request is successful.
 * @param {boolean} [needRedirect=true] - Determines whether to redirect to the reset password page after the request is successful.
 */
export const forgotPasswordRequest = (params, cb, needRedirect = true) =>
  createApiAction({
    method: 'forgotPassword',
    requestAction: c.FORGOT_PASSWORD_REQUEST,
    successAction: c.FORGOT_PASSWORD_SUCCESS,
    params,
    errorHandler(dispatch, { response }) {
      dispatch(
        enqueueSnackbar({
          message: response.data.message,
          options: {
            variant: 'error',
          },
        })
      );
      dispatch({
        type: c.FORGOT_PASSWORD_ERROR,
        payload: response.data.message,
      });
    },
    transformResponse: ({ data }) => data,
    afterSuccess: () => {
      if (cb) {
        cb();
      }
      if (needRedirect) {
        history.push('/reset-password');
      }
    },
  });

/**
 * Set's a new password using the received otp and introduced password.
 *
 * @param {object} params .
 * @param {string} params.otc - The otp received by the user.
 * @param {string} params.password - The new password to be set.
 * @param {Function} cb - The callback function to be executed after the request is successful.
 * @param {boolean} [needRedirect=true] - Determines whether to redirect to the reset password page after the request is successful.
 */
export const resetPasswordRequest = (params, cb, needRedirect = true) =>
  createApiAction({
    method: 'resetPassword',
    requestAction: c.RESET_PASSWORD_REQUEST,
    successAction: c.RESET_PASSWORD_SUCCESS,
    params,
    errorHandler(dispatch, { response }) {
      dispatch(
        enqueueSnackbar({
          message: response.data.message,
          options: {
            variant: 'error',
          },
        })
      );
      dispatch({
        type: c.RESET_PASSWORD_ERROR,
        payload: response.data.message,
      });
    },
    transformResponse: ({ data }) => data,
    afterSuccess: (dispatch) => {
      if (cb) {
        cb();
      }
      dispatch(
        enqueueSnackbar({
          message: <FormattedMessage id="resetPassword.passwordWasReset" />,
          options: {
            variant: 'success',
          },
        })
      );
      if (needRedirect) {
        history.push('/login');
      }
    },
  });

export const checkTokenSuccess = (payload) => ({
  type: c.CHECK_TOKEN_SUCCESS,
  payload,
});

