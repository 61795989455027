import { makeStyles } from '@material-ui/core';
import { lightGray } from '../../../constants/colors';

const useStyles = makeStyles(() => ({
  timeline: {
    padding: '8px 16px',
    marginBottom: '8px',
    boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
    '& li:last-of-type': {
      '& .MuiTimelineConnector-root': {
        display: 'none',
      },
    },
  },
  timelineItem: {
    alignItems: 'center',
    minHeight: 32,
    '&::before': {
      content: 'none',
    },
  },
  timelineContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  cardHeader: {
    '& p': {
      color: lightGray,
      fontWeight: 'bold',
    },
  },
}));

export default useStyles;
