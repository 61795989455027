import { makeStyles } from '@material-ui/core';
import { borderColor, white, lightGray } from 'constants/colors';

const useStyles = makeStyles(({ breakpoints }) => ({
  cardMediaStyle: {
    borderRadius: '50%',
    height: 90,
    width: 90,
    border: `2px solid ${white}`,
    backgroundColor: white,
    // flex: 1,
    [breakpoints.down('xs')]: {
      height: 89,
      width: 89,
      marginBottom: 9,
      flex: 'none',
    },
  },
  iconStyles: {
    borderRadius: '50%',
    padding: '8px',
    width: 40,
    height: 40,
    outline: `1px solid ${white}`,
  },
  cardContent: {
    padding: '0px 0px 0px 16px !important',
    [breakpoints.down('xs')]: {
      flex: 'none',
      padding: '0px !important',
    },
    '& p': {
      '& span': {
        color: lightGray,
      },
    },
  },
  descItem: {
    padding: '0 8px 0 8px',
    '& p': {
      paddingRight: '20px',
    },
  },
  divider: {
    width: 1,
    background: borderColor,
    margin: '0 16px',
  },
  additionalInfo: {
    display: 'flex',
    gap: '8px',
  },
  clientCard: {
    padding: '8px 16px',
    marginBottom: '8px',
    boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
  },
  cardHeader: {
    '& p': {
      color: lightGray,
      fontWeight: 'bold',
    },
  },
  mainContent: {
    marginBottom: '8px',
  },
  secondaryContent: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '8px',
  },
  callTo: {
    color: 'inherit',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  familyContainer: {
    '& > *': {
      '&:not(:last-child):after': {
        content: '" - "',
      },
    },

  }
}));

export default useStyles;
