import { makeStyles } from '@material-ui/core';
import { lightGray, darkPaper, white } from '../../../constants/colors';

const useStyles = makeStyles({
  container: {
    backgroundColor: darkPaper,
    display: 'flex',
    // height: 190,
    position: 'relative',
  },
  title: {
    color: darkPaper,
    fontFamily: 'Rubik',
    fontWeight: 500,
    marginBottom: 4,
  },
  info: {
    color: '#6A6A6A',
    marginBottom: 20,
  },
  actionBtn: {
    height: 32,
    backgroundColor: white,
    width: 140,
    boxShadow: 'none',
    fontWeight: 'bold',
    marginTop: 20,
    transition: 'all 0.3s ease',
    '&:first-of-type': {
      marginRight: 16,
    },
    '&:hover': {
      backgroundColor: white,
    },
  },
  cancelBtn: {
    border: `2px solid ${lightGray}`,
    color: lightGray,
    '&:hover': {
      border: `2px solid ${lightGray}`,
    },
  },
  sendBtn: {
    border: `2px solid ${darkPaper}`,
    color: darkPaper,
    '&:hover': {
      border: `2px solid ${darkPaper}`,
    },
  },
  root: {
    color: '#6A6A6A',
    border: '1px solid #E9E9E9',
    maxWidth: 300,
  },
  input: {
    color: darkPaper,
    fontWeight: 500,
    '&:-webkit-autofill': {
      boxShadow: '0 0 0 100px white inset',
      textFillColor: darkPaper,
    },
  },
  label: {
    color: '#6A6A6A',
  },
  focused: {
    color: `${darkPaper} !important`,
  },
  closeIcon: {
    position: 'absolute',
    top: 14,
    right: 14,
    zIndex: 2,
  },
  triggerModal: {
    height: 32,
    width: 32,
    borderRadius: '50%',
    border: `2px solid ${white}`,
  },
});

export default useStyles;
