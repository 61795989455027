import { createReducer } from 'utils';
import * as c from 'constants/actions';
import { IsModalStoreType } from 'types';

const initialState: IsModalStoreType = {
  type: null,
  isOpen: false,
};

export default createReducer(initialState, {
  [c.HANDLE_IS_OPEN_MODAL]: (state: IsModalStoreType, { payload } : { payload: IsModalStoreType}) => ({
    ...state,
    ...payload,
  }),
});
