import React from 'react';
import { Button } from '@material-ui/core';
import { AdvancedMarker,  InfoWindow, useAdvancedMarkerRef } from '@vis.gl/react-google-maps';
import { FormattedMessage } from 'react-intl';
import StyledMarkerContent from '../styled-marker-content';
import { useDispatch } from 'react-redux';
import { assignAgentRequest } from 'actions/panic';
import { FamilyDetailsType, PanicStatus } from 'types';

type Props = {
  panicId: number;
  status: string;
  position: { lat: number; lng: number };
  agentName: string;
  agentId: number;
  providerId?: number;
  panicStatus: PanicStatus;
  agent: FamilyDetailsType
}

const AvailableAgentMarker = ({ panicId, status, position, agentName, agentId, panicStatus, agent }: Props) => {
  const [markerRef, marker] = useAdvancedMarkerRef();
  const [showInfoWindow, setShowInfoWindow] = React.useState(false);
  const dispatch = useDispatch();

  const handleAssign = () => {
    const params = {
      id: panicId,
      data: {
        agentId,
        providerId: agent.userProviders[0].providerId,
        reassign: panicStatus === PanicStatus.deassigned,
      },
    }
    dispatch(assignAgentRequest({ params, cb: () => setShowInfoWindow(false) }));
  }

  return (
    <>
      <AdvancedMarker
        position={position}
        ref={markerRef}
        onClick={() => {
          setShowInfoWindow((prevState) => !prevState)
        }}
      >
        <StyledMarkerContent status={status}>
          {agentName}
        </StyledMarkerContent>
      </AdvancedMarker>
      {showInfoWindow && <InfoWindow anchor={marker} onCloseClick={() => {}}>
        <Button variant="contained" size='small' onClick={() => {handleAssign()}}>
          <FormattedMessage id="common.assign" defaultMessage="Assign" />
        </Button>
      </InfoWindow>}
    </>
  );
};

export default AvailableAgentMarker;
