import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Typography } from '@material-ui/core';
import { white } from '../../../constants/colors';
import clsx from 'clsx';


const useStyles = makeStyles({
  containerFixed: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 10000,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    flexDirection: 'column',
    position: 'fixed',
    backgroundColor: 'rgba(0, 0, 0, 0.45)',
  },
  containerAbsolute: {
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
  },
  colorPrimary: {
    color: white,
  },
});

interface Props {
  isLoading?: boolean;
  inContainer?: boolean;
  style?: React.CSSProperties;
  text?: React.ReactNode;
}

const Loader = ({ isLoading, inContainer, style, text }: Props) => {
  const { containerFixed, containerAbsolute, colorPrimary } = useStyles();
  if (!isLoading) return null;
  return (
    <div className={clsx(containerFixed,{ [containerAbsolute]: inContainer })} style={style}>
      {text && (
        <Typography variant="h6" className={colorPrimary}>
          {text}
        </Typography>
      )}

      <CircularProgress size={40} thickness={5} classes={{ colorPrimary }} />
    </div>
  );
};

export default Loader;
