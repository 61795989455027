import Client from './client';
import { LS_TOKEN_KEY } from '../constants';
import { jwtDecode, checkIfTokenIsExpired } from '../utils';

const backendUrl = process.env.REACT_APP_ENDPOINT || 'http://localhost:8000';

const api = Client.create(backendUrl);

if (typeof window !== 'undefined') {
  const token = window.localStorage.getItem(LS_TOKEN_KEY);
  if (token) {
    if (checkIfTokenIsExpired(jwtDecode(token).exp)) {
      api.setJwtToken(null);
      window.localStorage.removeItem(LS_TOKEN_KEY);
    } else {
      api.setJwtToken(token);
    }
  }
}

api.addErrorInterceptor((error) => {
  return Promise.reject(error);
});

export const BACKEND_URL = backendUrl;

export default api;
