import React, { useState } from 'react';
import { Box } from '@material-ui/core';

import Dialog from './dialog';

interface Props {
  src?: string;
  children: any;
  images?: Array<string>;
}

const FullScreenImage = ({ src, children, images = [] }: Props) => {
  const [isFullScreen, handleIsFullScreen] = useState(false);
  const haveImage = src || images?.length > 0;

  const toggleFullScreen = () => {
    if (haveImage) {
      handleIsFullScreen((state) => !state);
    }
  };

  return (
    <>
      <Box onClick={toggleFullScreen} style={{ cursor: haveImage ? 'pointer' : 'inherit' }}>
        {children}
      </Box>
      {isFullScreen && <Dialog toggle={toggleFullScreen} src={src} images={images} />}
    </>
  );
};

export default FullScreenImage;
