import React, { ComponentProps } from 'react';
import { TextField, Box, Typography } from '@material-ui/core';
import { ICustomField, ICustomForm } from '../../types';
import { FormattedMessage } from 'react-intl';
import { error } from '../../constants/colors';

type Props = {
  field?: ICustomField;
  form: ICustomForm;
  label?: string;
  showLabel?: boolean;
  suppressMargin?: boolean;
  margin: ComponentProps<typeof TextField>['margin'];
};

const CustomTextField = ({ field, form, label, showLabel, suppressMargin, ...props }: Props) => {
  const { touched, errors } = form;
  const fieldName = field && field.name ? field.name : '';

  return (
    <Box position="relative">
      {showLabel && label && (
        <div style={{ color: '#AEAEAE', margin: '8px 0 12px' }}>
          <Typography variant="body2" style={{ lineHeight: '14px' }}>
            {label}
          </Typography>
        </div>
      )}
      <TextField
        error={touched[fieldName] && !!errors[fieldName]}
        fullWidth
        variant="filled"
        autoComplete="new-password"
        label={label}
        {...field}
        {...props}
        margin={
          !suppressMargin && touched[fieldName] && !!errors[fieldName] ? 'normal' : props.margin
        }
      />
      {touched[fieldName] && !!errors[fieldName] && (
        <Box color={error} position="absolute" bottom="12px" left="10px" height="12px">
          <Typography variant="caption">
            {errors && fieldName && errors[fieldName]?.toString()?.startsWith('CUSTOM_MESSAGE') ? (
              <FormattedMessage id={errors[fieldName]} defaultMessage="Required" />
            ) : (
              errors[fieldName]
            )}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

CustomTextField.defaultProps = {
  field: {
    name: '',
    value: '',
    onChange: () => null,
  },
  form: {
    touched: {},
    error: {},
  },
};

export default CustomTextField;
