import { makeStyles } from '@material-ui/core';

import { gray, lightGray, white } from '../../../constants/colors';

const useStyles = makeStyles({
  container: {
    marginTop: 4,
    position: 'relative',
    '&:hover': {
      margin: ({ disabled }: { disabled?: boolean }) => !disabled && 0,
      height: ({ disabled }: { disabled?: boolean }) => !disabled && 52,
      borderRadius: ({ disabled }: { disabled?: boolean }) => !disabled && 26,
      border: ({ disabled }: { disabled?: boolean }) => !disabled && `2px solid ${white}`,
      boxShadow: ({ disabled }: { disabled?: boolean }) => !disabled && 'none',
      '&:after': {
        display: ({ disabled }: { disabled?: boolean }) => !disabled && 'none',
      },
    },
    '&:after': {
      content: "''",
      position: 'absolute',
      height: 52,
      top: -6,
      left: -6,
      right: -6,
      bottom: -6,
      borderRadius: 26,
      border: `2px solid${gray}`,
      zIndex: 0,
    },
    '&:disabled': {
      backgroundColor: 'transparent',
      color: lightGray,
      border: `2px solid ${gray}`,
    },
  },
});

export default useStyles;
