import { connect } from 'react-redux';
import {
  loginRequest,
  logoutRequest,
  registerRequest,
  resetPasswordRequest,
  forgotPasswordRequest,
} from '../actions/auth';
import { IAppState, IAuthStoreType } from '../types';

interface MyReduxProps {
  auth: IAuthStoreType;
}

const mapStateToProps = ({ auth }: IAppState): MyReduxProps => ({ auth });

const mapDispatchToProps = {
  loginRequest,
  logoutRequest,
  registerRequest,
  resetPasswordRequest,
  forgotPasswordRequest,
};

export default connect(mapStateToProps, mapDispatchToProps);
