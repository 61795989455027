import * as c from '../constants/actions';
import { INTL_KEY } from '../constants';
import createApiAction from '../api/createApiAction';

export const setLanguageRequest = (params) =>
  createApiAction({
    method: 'setLanguage',
    params,
    requestAction: c.SET_LANGUAGE_REQUEST,
    successAction: c.SET_LANGUAGE_SUCCESS,
    errorAction: c.SET_LANGUAGE_ERROR,
    transformResponse: ({ data }) => data,
    afterSuccess: (dispatch) => {
      dispatch(setLocale(params.lang));
    },
    errorHandler(dispatch) {
      dispatch(setLocale(params.lang));
    },
  });

export const setLocale = (payload) => {
  window.localStorage.setItem(INTL_KEY, payload);
  return {
    type: c.SET_LOCALE,
    payload,
  };
};

export default {
  setLocale,
  setLanguageRequest,
};
