import api from './index';
import ApiClient from './client';

let cachedRequests = [];

const middleware = ({ dispatch }) =>
  (next) =>
    async (action) => {
      if (!action[ApiClient.ACTION_KEY]) {
        next(action);
        return;
      }

      const {
        method,
        params,
        requestAction,
        requestPayload,
        successAction,
        errorAction,
        transformResponse,
        afterSuccess,
        useCache,
        errorHandler,
      } = action;

      try {
        const cacheKey = JSON.stringify({ params, method });

        if (useCache) {
          if (cachedRequests.includes(cacheKey)) {
            return;
          }

          cachedRequests.push(cacheKey);
        }
        if (requestPayload) {
          dispatch({ type: requestAction, payload: requestPayload });
        } else {
          dispatch({ type: requestAction });
        }

        const response = await api[method](params);

        cachedRequests = cachedRequests.filter((key) => key !== cacheKey);
        dispatch({
          type: successAction,
          payload: transformResponse ? transformResponse(response) : response,
        });
        if (afterSuccess) {
          afterSuccess(dispatch, response);
        }
      } catch (error) {
        if (errorHandler) {
          const reshapeError = {
            ...error,
            response: {
              data: {
                message: error.message,
              },
            },
          };
          const err = error.response ? error : reshapeError;
          errorHandler(dispatch, err);
        } else {
          dispatch({
            type: errorAction,
            error,
          });
        }
      }
    };

export default middleware;
