import React from 'react';
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Theme,
  Toolbar,
  Typography,
  withStyles
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from '../styles';
import { PanicStatus } from 'types';
import {
  rejectCancelationRequest,
  cancelPanicRequest,
  panicDoneRequest,
} from 'actions/panic';
import { createSelector } from 'reselect';

import { IAppState } from 'types';
import { FormattedMessage } from 'react-intl';
import { green } from '@material-ui/core/colors';

type Props = {
  panicId: number;
  toggleModal: () => void;
}

const ColorButton = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
    '&:hover': {
      color: theme.palette.getContrastText(green[700]),
      backgroundColor: green[700],
    },
  },
}))(Button);

const PanicModalHeader = ({ panicId, toggleModal }: Props) => {
  const dispatch = useDispatch();

  const panicStatusSelector = createSelector(
    (state: IAppState) => state.panic.byId[panicId],
    (panic) => panic?.status
  );
  const panicStatus = useSelector(panicStatusSelector);
  const classes = useStyles();
  const isCancelRequest = panicStatus === PanicStatus.cancel_request;
  const showCancel = ![PanicStatus.done, PanicStatus.canceled, PanicStatus.cancel_request].includes(
    panicStatus
  );
  const showFinalize = [PanicStatus.inplace, PanicStatus.processed, PanicStatus.extended].includes(panicStatus);

  return (
    <AppBar className={classes.appBar} color="default">
      <Toolbar className={classes.toolbar}>
        {isCancelRequest && (
          <Box display="flex" flex={1} gridGap={16} alignItems="center">
            <Typography
              className={classes.cancelRequestTitle}
              variant='h4'
            >
              <FormattedMessage id="panic_statuses.cancel_request" defaultMessage="Client requested to cancel the call" />
            </Typography>
            <Button
              size="small"
              onClick={() => dispatch(cancelPanicRequest({ id: panicId, cb: toggleModal }))}
              color="default"
              variant="contained"
            >
              <FormattedMessage id="common.confirm" defaultMessage="Confirm" />
            </Button>
            <Button
              size="small"
              onClick={() => dispatch(rejectCancelationRequest({ id: panicId, cb: toggleModal }))}
              color="default"
              variant="text"
            >
              <FormattedMessage id="common.reject" defaultMessage="Reject" />
            </Button>
          </Box>
        )}
        <Box display="flex" gridGap={16}>
          {showCancel && (
            <Button
              size="small"
              onClick={() => dispatch(cancelPanicRequest({ id: panicId, cb: toggleModal }))}
              color="secondary"
              variant="outlined"
            >
              <FormattedMessage id="common.cancel_alarm" defaultMessage="Cancel Alarm" />
            </Button>
          )}
          {showFinalize && (
            <ColorButton
              size="small"
              onClick={() => dispatch(panicDoneRequest({ id: panicId, cb: toggleModal }))}
              color="secondary"
              variant="outlined"
            >
              <FormattedMessage id="common.finalize" defaultMessage="Finalize" />
            </ColorButton>
          )}
        </Box>
        <IconButton color="inherit" onClick={toggleModal} aria-label="Close" className="close-icon">
          <CloseIcon />
        </IconButton>

      </Toolbar>
    </AppBar>
  );
};
export default PanicModalHeader;
