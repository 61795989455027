import React from 'react';
import { MenuItem, TextField, Typography } from '@material-ui/core';
import { ICustomField, ICustomForm } from '../../types';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

type Props = {
  field?: ICustomField;
  options: Array<any>;
  form: ICustomForm;
  label?: string;
  showLabel?: boolean;
  SelectProps?: any;
};

const CustomSelect = ({ field, form, options, label, showLabel, ...props }: Props) => {
  const { touched, errors } = form;
  const fieldName = field?.name || '';
  return (
    <div>
      {showLabel && label && (
        <div style={{ color: '#AEAEAE', margin: '8px 0 12px' }}>
          <Typography variant="body2" style={{ lineHeight: '14px' }}>
            {label}
          </Typography>
        </div>
      )}
      <TextField
        helperText={touched[fieldName] ? errors[fieldName] : ''}
        error={touched[fieldName] && !!errors[fieldName]}
        fullWidth
        variant="filled"
        label={label}
        select
        {...field}
        {...props}
      >
        {options.map((option) => (
          <MenuItem
            key={option?.value || option}
            value={[undefined, null].includes(option.value) ? option : option.value}
            disabled={option.disabled}
          >
            <div style={{ display: 'flex' }}>
              {props.SelectProps?.multiple ? (
                <>
                  {(form as any).values[fieldName]?.includes(
                    [undefined, null].includes(option.value) ? option : option.value
                  ) ? (
                      <div style={{ width: '40px' }}>
                        <CheckIcon />
                      </div>
                    ) : (
                      <div style={{ width: '40px' }}>
                        <CloseIcon />
                      </div>
                    )}
                </>
              ) : null}
              {option.label || option}
            </div>
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
};

CustomSelect.defaultProps = {
  field: {
    value: '',
    onChange: () => null,
  },
};

export default CustomSelect;
