
import React, { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { IAppState, RouteHistoryObject } from 'types';
import { useMap } from '@vis.gl/react-google-maps';
import moment from 'moment';
import StyledMarkerContent from '../styled-marker-content';
import { AdvancedMarker } from '@vis.gl/react-google-maps';

import { pathDrawSpeed, strokeColorMap, infoWindowAriaLabel } from 'constants/map-settings';
import PanicMarker from './panic-marker';

type Props = {
  userName?: string;
  agentName?: string;
  panicId: number;
}

const RouteHistoryMarkers = ({ userName, agentName, panicId }: Props) => {
  // keep markers refs to remove them on unmount
  const markers = useRef<google.maps.Marker[]>([]);
  const infoWindow = new google.maps.InfoWindow({
    ariaLabel: infoWindowAriaLabel,
  });

  const map = useMap();


  const { userRouteList, agentRouteList } = useSelector((state: IAppState) => ({
    userRouteList: state.panic.routeHistory.user,
    agentRouteList: state.panic.routeHistory.agent,
  }));




  const userFirstPoint = userRouteList[0] && new google.maps.LatLng(Number(userRouteList[0].lat), Number(userRouteList[0].lng))
  const agentFirstPoint = agentRouteList[0] && new google.maps.LatLng(Number(agentRouteList[0].lat), Number(agentRouteList[0].lng))


  const handlePathDraw = useCallback((color: string, array: RouteHistoryObject[]) => {

    // create empty polyline for each array
    const polyPath = new google.maps.Polyline({
      path: [],
      geodesic: true,
      strokeColor: color,
      strokeOpacity: 0.6,
      strokeWeight: 6,
    });

    polyPath.setMap(map);


    array.forEach(({ lat, lng, createdAt }, index) => {
      // gradually add points to the polyline
      setTimeout(() => {
        const latLng = new google.maps.LatLng(parseFloat(lat), parseFloat(lng));
        const path = polyPath.getPath();
        path.push(latLng);

        // ignore first marker since we are rendering it as an advanced marker
        if (index > 0) {

          const marker = new google.maps.Marker({
            position: latLng,
            map: map,
            title: moment(createdAt).format('DD-MM-YYYY H:mm:ss'),
            icon: {
              path: google.maps.SymbolPath.CIRCLE,
              scale: index == 0 || index === array.length - 1 ? 10 : 5,
              strokeColor: color,
              fillColor: 'white',
              fillOpacity: 1,
              strokeOpacity: 0.8,
              strokeWeight: 4,
            },
          });

          marker.addListener('mouseover', () => {
            infoWindow.setContent(moment.utc(createdAt).local().format('DD-MM-YYYY H:mm:ss'));
            infoWindow.open(marker.getMap(), marker);
          });
          marker.addListener('mouseout', () => {
            infoWindow.close();
            infoWindow.setContent(null);
          });
          markers.current.push(marker);
        }

        // use 200ms step when array.length < 5
      }, Math.min(pathDrawSpeed / array.length, 200) * index);
    })
    return polyPath;
  }, [map])


  useEffect(() => {
    // zoom in/out so all the user & agent path fits on the map
    if (map && (Math.max(userRouteList.length, agentRouteList.length) > 0)) {
      const bounds = new google.maps.LatLngBounds();
      [...userRouteList, ...agentRouteList].forEach(({ lat, lng }) => {
        bounds.extend(new google.maps.LatLng(Number(lat), Number(lng)));
      });

      map.fitBounds(bounds, 100);
    }

    const userPath = handlePathDraw(strokeColorMap['user'], userRouteList);
    const agentPath = handlePathDraw(strokeColorMap['agent'], agentRouteList);

    return () => {
      userPath.setMap(null); // remove polyline from map on unmount
      agentPath.setMap(null); // remove polyline from map on unmount

      markers.current.forEach((marker) => {
        marker.setMap(null); // remove markers from map on unmount
      });
    }
  }, [JSON.stringify(userRouteList), JSON.stringify(agentRouteList), map]);


  // show panic marker if there are no user & agent routes
  if (!userRouteList[0] && !agentRouteList[0]) {
    return <PanicMarker panicId={panicId} />
  }

  return (
    <>
      {userRouteList[0] && (
        <AdvancedMarker
          position={userFirstPoint}
        >
          <StyledMarkerContent status={strokeColorMap['user']} >
            {userName}
          </StyledMarkerContent>
        </AdvancedMarker>
      )}
      {agentRouteList[0] && (
        <AdvancedMarker
          position={agentFirstPoint}
        >
          <StyledMarkerContent status={strokeColorMap['agent']}>
            {agentName}
          </StyledMarkerContent>
        </AdvancedMarker>
      )}
    </>
  )
};

export default RouteHistoryMarkers;
