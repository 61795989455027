import { makeStyles } from '@material-ui/core/styles';
/* eslint-disable max-len */
const useStyels = makeStyles({
  checkbox: {
    height: '18px',
    width: '18px',
    cursor: 'pointer',
    outline: 'none',
    backgroundImage:
      "url(\"data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='9' cy='9' r='8.5' stroke='%23333333' stroke-opacity='0.15'/%3E%3C/svg%3E%0A\")",
  },
  active: {
    height: '18px',
    width: '18px',
    cursor: 'pointer',
    outline: 'none',
    backgroundImage:
      "url(\"data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM13.7682 6.64018C14.1218 6.21591 14.0645 5.58534 13.6402 5.23178C13.2159 4.87821 12.5853 4.93554 12.2318 5.35982L8.63383 9.67736C8.28711 10.0934 8.09219 10.3238 7.93658 10.4653L7.93063 10.4707L7.92422 10.4659C7.75643 10.339 7.54146 10.1272 7.1585 9.74428L5.70711 8.29289C5.31658 7.90237 4.68342 7.90237 4.29289 8.29289C3.90237 8.68342 3.90237 9.31658 4.29289 9.70711L5.74428 11.1585L5.78511 11.1993L5.78512 11.1993C6.11161 11.526 6.4257 11.8402 6.71794 12.0611C7.0453 12.3087 7.47402 12.5415 8.02343 12.5165C8.57284 12.4916 8.97872 12.221 9.28233 11.9448C9.55337 11.6983 9.83772 11.3569 10.1333 11.0021L10.1333 11.0021L10.1703 10.9577L13.7682 6.64018Z' fill='%2300BDD5'/%3E%3C/svg%3E%0A\")",
  },
});
/* eslint-enable max-len */

export default useStyels;
