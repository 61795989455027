import { defaultLang } from 'constants/index';
import * as c from '../constants/actions';
import { createReducer } from '../utils';
import { IAuthStoreType, ProfileDataType } from 'types';

const initialState: IAuthStoreType = {
  isAuthenticated: false,
  show2FaModal: false,
  data: {
    '2fa': 0,
    '2fa_code': '',
    id: 0,
    login: '',
    role: '',
    phone: '',
    name: '',
    is_approved: 0,
    providers: [],
    language: defaultLang,
  },
  onLoginToken: '',
  isLoading: false,
  editLoading: false,
  error: null,
};

interface LoginPayloadType {
  type: string;
  payload: {
    token?: string;
    tokenData: {
      accessToken: string;
    }
  };
}

interface UserPayloadType {
  type: string;
  payload: ProfileDataType;
}

export default createReducer(initialState, {
  [c.LOGIN_REQUEST]: (state: IAuthStoreType): IAuthStoreType => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.LOGIN_SUCCESS]: (state: IAuthStoreType, { payload }: LoginPayloadType): IAuthStoreType => ({
    ...state,
    isAuthenticated: !!payload?.tokenData?.accessToken,
    data: { ...state.data, ...payload },
    onLoginToken: payload?.tokenData?.accessToken,
    isLoading: false,
  }),
  [c.LOGIN_ERROR]: (state: IAuthStoreType, { payload }: any): IAuthStoreType => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.GET_USER_INFO_REQUEST]: (state: IAuthStoreType): IAuthStoreType => ({
    ...state,
    isAuthenticated: true,
    error: null,
    isLoading: true,
  }),
  [c.GET_USER_INFO_SUCCESS]: (state: IAuthStoreType, { payload }: UserPayloadType): IAuthStoreType => ({
    ...state,
    isAuthenticated: true,
    data: { ...state.data, ...payload, role: payload.providers[0]?.UserProviderModel?.role ?? '' },
    isLoading: false,
  }),
  [c.GET_USER_INFO_ERROR]: (state: IAuthStoreType, { payload }: any): IAuthStoreType => ({
    ...state,
    isAuthenticated: false,
    error: payload,
    isLoading: false,
  }),
  [c.UPDATE_USER_INFO_SUCCESS]: (state: IAuthStoreType, { payload }: UserPayloadType): IAuthStoreType => ({
    ...state,
    data: payload,
    editLoading: false,
    error: null,
  }),
  [c.UPDATE_USER_INFO_REQUEST]: (state: IAuthStoreType): IAuthStoreType => ({
    ...state,
    editLoading: true,
    error: null,
  }),
  [c.UPDATE_USER_INFO_ERROR]: (state: IAuthStoreType, { payload }: any): IAuthStoreType => ({
    ...state,
    editLoading: false,
    error: payload,
  }),
  [c.SHOW_2FA_MODAL]: (state: IAuthStoreType): IAuthStoreType => ({
    ...state,
    show2FaModal: true,
  }),
  [c.CLOSE_2FA_MODAL]: (state: IAuthStoreType): IAuthStoreType => ({
    ...state,
    show2FaModal: false,
  }),
  [c.SET_LOCALE]: (state: IAuthStoreType, { payload }: any) => ({
    ...state,
    settings: { lang: payload },
  }),
  [c.RESET_PASSWORD_SUCCESS]: (state: IAuthStoreType): IAuthStoreType => ({
    ...state,
    editLoading: false,
    error: null,
  }),
  [c.RESET_PASSWORD_REQUEST]: (state: IAuthStoreType): IAuthStoreType => ({
    ...state,
    editLoading: true,
    error: null,
  }),
  [c.RESET_PASSWORD_ERROR]: (state: IAuthStoreType, { payload }: any): IAuthStoreType => ({
    ...state,
    editLoading: false,
    error: payload,
  }),
});
