import 'moment/locale/ro';
import 'moment/locale/ru';
import 'moment/locale/en-gb';
import moment from 'moment';
import { LocaleVariants } from 'types';

const formatDate = (date: Date | string | number, formatting: string = 'MMMM DD, YYYY HH:mm', locale: LocaleVariants) => {
  const instance = moment.utc(date).local();

  instance.locale(locale);

  return instance.format(formatting);
};

export default formatDate;
