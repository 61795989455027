import React, { useMemo } from 'react';
import { FormHelperText, Button, Box } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

type Props = {
  setFieldValue: (name: string, el?: any) => void;
  helperText?: any;
  name: string;
  value?: string | File;
  setFieldError: (name: string, error: string) => void;
  setFieldTouched: (name: string, touched: boolean, validate?: boolean) => void;
};

const FileUpload = ({ setFieldValue, helperText, name, value, setFieldError, setFieldTouched }: Props) => {
  const handleClick = (el?: File) => {
    setFieldError(name, '');
    if (el && el?.size > 5000000) {
      setFieldError(name, (<FormattedMessage id="validation.maxSize" values={{ max: 5 }} />) as any);
      setFieldTouched(name, true, false);
    } else {
      setFieldValue(name, el);
    }
  };
  const filePreview = useMemo(() => {
    if (value instanceof File) {
      return URL.createObjectURL(value);
    }
    return null;
  }, [value]);

  return (
    <Box m={2}>
      <span key={name}>
        <label htmlFor="raised-button-file">
          <input
            accept="image/*"
            id="raised-button-file"
            type="file"
            name={name}
            hidden
            onChange={(event?: any) => handleClick(event.currentTarget.files[0])}
          />
          <Box display="flex" alignItems="center">
            <Button size="small" component="div" variant="outlined">
              <FormattedMessage id="common.upload" defaultMessage="Upload" />
            </Button>
            <Box ml={2} overflow="hidden">
              {value instanceof File ? (
                value.name
              ) : (
                <FormattedMessage id="common.selectFile" defaultMessage="Select file" />
              )}
            </Box>
          </Box>
        </label>
      </span>
      {value instanceof File && filePreview && (
        <Box m={2}>
          <img src={filePreview} alt="preview" width="100%" height="auto" />
        </Box>
      )}
      {helperText && <FormHelperText error>{helperText}</FormHelperText>}
    </Box>
  );
};

FileUpload.defaultProps = {
  helperText: '',
};

export default FileUpload;
