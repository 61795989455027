import React from 'react';
import {
  Place,
  FileCopy,
  Favorite,
  Settings,
} from '@material-ui/icons';
import { operator } from '../../constants/roles';

const menuItems = [
  {
    name: 'sidebar.history',
    path: '/history',
    icon: <FileCopy />,
    allowedRoles: [operator],
  },
  {
    name: 'sidebar.agents',
    path: '/agents',
    icon: <Favorite />,
    allowedRoles: [operator],
  },
  {
    name: 'sidebar.map',
    path: '/map',
    icon: <Place />,
    allowedRoles: [operator],
  },
  {
    name: 'sidebar.settings',
    path: '/settings',
    icon: <Settings />,
    allowedRoles: [operator],
  },
];

export default menuItems;
