import React from 'react';
import { useSelector } from 'react-redux';
import { IAppState, PanicStatus } from 'types';
import moment from 'moment';
import AvailableAgentMarker from './available-agent-marker';
import { createSelector } from 'reselect';

export const agentStatusColor = (online: boolean, eta?: number) => {
  if (!online) {
    return 'gray';
  }
  // if eta exists then agent is busy
  if (typeof eta === 'number') {
    return 'blue';
  }
  return 'green';
};

type Props = {
  panicId: number;
  panicStatus: PanicStatus
}

const PanicAllAgentMarkers = ({ panicId, panicStatus }: Props) => {

  const agentCoordsSelector = createSelector(
    ({ family }: IAppState) => Object.values(family.agentsLocation)
      .filter((agent) => (
        family.availableIds.includes(agent.agentId) &&
        Object.prototype.hasOwnProperty.call(family.byId, agent.agentId) &&
        (agent.timeISO && moment().diff(moment(agent.timeISO), 'days') < 8))
      ),
    (agentCoords) => agentCoords
  );

  const agentCoords = useSelector(agentCoordsSelector);
  const agentListById = useSelector(({ family }: IAppState) => family.byId);

  return (
    <>
      {agentCoords.map((agent) => (
        <AvailableAgentMarker
          key={agent.agentId}
          status={agentStatusColor(agent.online, agent.eta)}
          agentName={agentListById[agent.agentId]?.fullName}
          position={{ lat: Number(agent.lat), lng: Number(agent.lon) }}
          panicId={panicId}
          agentId={agent.agentId}
          agent={agentListById[agent.agentId]}
          panicStatus={panicStatus}
        />
      ))}
    </>
  );
};

export default PanicAllAgentMarkers;
