import { makeStyles } from '@material-ui/core/styles';
import { primaryColor } from 'constants/colors';

const pointerHeight = 8;
const markerBorderWidth = 4;

type MarkerStyles = {
  status?: string;
}

const agentDefaultColor = primaryColor[400];

export const useStyles = makeStyles(() => ({
  panicMarker: {
    backgroundColor: 'white',
    borderRadius: '12px',
    color: 'black',
    padding: '4px',
    position: 'relative',
    marginBottom: `${pointerHeight}px`,
    border: ({ status }: MarkerStyles) => `${markerBorderWidth}px solid ${status || agentDefaultColor}`,
  },
  pointer: {
    position: 'absolute',
    left: '50%',
    top: `calc(100% + ${markerBorderWidth}px)`,
    transform: 'translate(-50%, 0)',
    width: 0,
    height: 0,
    borderLeft: `${pointerHeight}px solid transparent`,
    borderRight: `${pointerHeight}px solid transparent`,
    borderTop: ({ status }: MarkerStyles) => `8px solid ${status || agentDefaultColor}`,
  },
}));

