import { CRM_TOKEN } from '../constants';
import { isProd } from '../constants/index';

export const CRM_URL = 'https://platon.progression.md/v4/app/gardian.crm.add';

export const sendLogToCrm = (body: any): void => {
  if (!isProd) {
    return;
  }
  const logData = {
    ...body,
    token: CRM_TOKEN,
    lead_name: 'Паника появилась',
    client_id: 29137642,
    status_id: 40701613,
    pipeline_id: 4315240,
    note: isProd ? 'PRODUCTION' : 'DEV',
  };
  fetch(CRM_URL, {
    method: 'post',
    mode: 'cors',
    body: JSON.stringify(logData),
  }).catch((err) => {
    // eslint-disable-next-line no-console
    console.log('sendLogToCrm error', err);
  });
};
