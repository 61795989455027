import { LocaleVariants } from 'types';
import { INTL_KEY, langOptions, defaultLang } from '../constants';

const isLangKeyValid = (key: string | null) => Boolean(key) && langOptions.includes(key as LocaleVariants)
;
const getLangKeyFromLocalStorage = () => {
  const langKeyFromLocalStorage = window && window.localStorage.getItem(INTL_KEY);
  return isLangKeyValid(langKeyFromLocalStorage) ? langKeyFromLocalStorage : null;
};
const initialLangKey = getLangKeyFromLocalStorage() || defaultLang;

export default initialLangKey;
