import React from 'react';
import { Timeline, TimelineItem, TimelineSeparator, TimelineContent, TimelineDot } from '@material-ui/lab';
import { Box, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import PropTypes from 'prop-types';

import useStyles from './styles';

const PanicTimeline = ({ client, panic, agent }) => {
  const { timeline, timelineItem, timelineContent, cardHeader } = useStyles();

  const panicCreatedAt = panic?.createdAt || undefined;
  const panicClosedAt = panic?.closedAt|| undefined;

  return (
    <>
      <Timeline className={timeline}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2.5} className={cardHeader}>
          <Box>
            <Typography variant="body2">
              <FormattedMessage id="common.call_duration" />
            </Typography>
          </Box>
          <Box color="white">
            <Typography variant="body2">
              {`${moment(panicClosedAt).diff(moment(panicCreatedAt), 'minutes')} min`}
              {moment(panicClosedAt).diff(moment(panicCreatedAt), 'minutes') < 10 ? ` ${moment(panicClosedAt).diff(moment(panicCreatedAt), 'seconds') % 60} sec` : null}
            </Typography>
          </Box>
        </Box>
        {[
          {
            label: 'createdAt',
            role: 'client',
            info: `${client?.info?.firstName} ${client?.info?.midName ? client?.info?.midName : ''} ${client?.info?.lastName ? client?.info?.lastName : ''}`,
          },
          { label: 'assignedAt', role: 'operator' },
          {
            label: 'acceptedAt',
            role: 'agent',
            info: `${agent?.info?.firstName} ${agent?.info?.midName ? agent?.info?.midName : ''} ${agent?.info?.lastName ? agent?.info?.lastName : ''}`,
          },
          {
            label: 'inPlaceAt',
            role: 'agent',
            info: `${agent?.info?.firstName} ${agent?.info?.midName ? agent?.info?.midName : ''} ${agent?.info?.lastName ? agent?.info?.lastName : ''}`,
          },
          {
            label: 'extendedAt',
            role: 'client',
            info: `${client?.info?.firstName} ${client?.info?.midName ? client?.info?.midName : ''} ${client?.info?.lastName ? client?.info?.lastName : ''}`,
          },
          {
            label: 'savedAt',
            role: 'agent',
            info: `${agent?.info?.firstName} ${agent?.info?.midName ? agent?.info?.midName : ''} ${agent?.info?.lastName ? agent?.info?.lastName : ''}`,
          },
          {
            label: 'canceledRequestAt',
            role: 'client',
            info: `${client?.info?.firstName} ${client?.info?.midName ? client?.info?.midName : ''} ${client?.info?.lastName ? client?.info?.lastName : ''}`,
          },
          {
            label: 'closedAt',
            role: `${panic?.closedBy === 'operator' ? 'operator' : 'client'}`,
            info: `${panic?.closedBy === 'operator' ? '' :
              `${client?.info?.firstName} ${client?.info?.midName ? client?.info?.midName : ''} ${client?.info?.lastName ? client?.info?.lastName : ''}`}`,
          },
        ]
          .filter(({ label }) => panic?.[label])
          .map(({ label, role, info = '' }) => (
            <TimelineItem key={label} className={timelineItem}>
              <TimelineSeparator>
                <TimelineDot variant="outlined" />
                {/* <TimelineConnector /> */}
              </TimelineSeparator>
              <TimelineContent className={timelineContent}>
                <Box>
                  <FormattedMessage id={`panicLog.${label}`} defaultMessage={label} />
                  {' - '}
                  <b>
                    <FormattedMessage id={`common.${role}`} defaultMessage={role} />
                  </b>
                  {info.replace(' ', '').length ? ' - ' : ''}
                  <b>{info}</b>
                </Box>
                <Box>{moment(panic?.[label]).format('DD-MM-YYYY H:mm:ss')}</Box>
              </TimelineContent>
            </TimelineItem>
          ))}
      </Timeline>
    </>
  );
};

PanicTimeline.propTypes = {
  panic: PropTypes.objectOf(PropTypes.any).isRequired,
  client: PropTypes.objectOf(PropTypes.any),
  agent: PropTypes.objectOf(PropTypes.any),
};

export default PanicTimeline;
