import createReducer from '../utils/createReducer';
import * as c from 'constants/actions';
import initialLangKey from 'utils/language';
import { ITranslationStoreType, ProfileDataType, LocaleVariants } from 'types';

const initialState = {
  locale: initialLangKey,
};

export default createReducer(initialState, {
  [c.SET_LOCALE]: (state: ITranslationStoreType, { payload }: { payload: LocaleVariants}) => ({
    ...state,
    locale: payload,
  }),
  [c.GET_USER_INFO_SUCCESS]: (state: ITranslationStoreType, { payload }: { payload: ProfileDataType }) => ({
    ...state,
    locale: payload.language,
  })
});
