export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_ERROR = 'REGISTER_ERROR';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';

export const CHECK_TOKEN_SUCCESS = 'CHECK_TOKEN_SUCCESS';
export const SET_LOCALE = 'SET_LOCALE';

export const GET_USER_INFO_REQUEST = 'GET_USER_INFO_REQUEST';
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';
export const GET_USER_INFO_ERROR = 'GET_USER_INFO_ERROR';

export const GET_AGENT_LOCATIONS_REQUEST = 'GET_AGENT_LOCATIONS_REQUEST';
export const GET_AGENT_LOCATIONS_SUCCESS = 'GET_AGENT_LOCATIONS_SUCCESS';
export const GET_AGENT_LOCATIONS_ERROR = 'GET_AGENT_LOCATIONS_ERROR';

export const GET_AVAILABLE_AGENTS_REQUEST = 'GET_AVAILABLE_AGENTS_REQUEST';
export const GET_AVAILABLE_AGENTS_SUCCESS = 'GET_AVAILABLE_AGENTS_SUCCESS';
export const GET_AVAILABLE_AGENTS_ERROR = 'GET_AVAILABLE_AGENTS_ERROR';

export const SET_AGENT_AVAILABLE = 'SET_AGENT_AVAILABLE';
export const SET_AGENT_BUSY = 'SET_AGENT_BUSY';

export const UPDATE_USER_INFO_REQUEST = 'UPDATE_USER_INFO_REQUEST';
export const UPDATE_USER_INFO_SUCCESS = 'UPDATE_USER_INFO_SUCCESS';
export const UPDATE_USER_INFO_ERROR = 'UPDATE_USER_INFO_ERROR';

// panic actions
export const GET_PANIC_BY_ID_REQUEST = 'GET_PANIC_BY_ID_REQUEST';
export const GET_PANIC_BY_ID_SUCCESS = 'GET_PANIC_BY_ID_SUCCESS';
export const GET_PANIC_BY_ID_ERROR = 'GET_PANIC_BY_ID_ERROR';

export const REJECT_PANIC_CANCEL_REQUEST = 'REJECT_PANIC_CANCEL_REQUEST';
export const REJECT_PANIC_CANCEL_SUCCESS = 'REJECT_PANIC_CANCEL_SUCCESS';
export const REJECT_PANIC_CANCEL_ERROR = 'REJECT_PANIC_CANCEL_ERROR';

export const CANCEL_PANIC_REQUEST = 'CANCEL_PANIC_REQUEST';
export const CANCEL_PANIC_SUCCESS = 'CANCEL_PANIC_SUCCESS';
export const CANCEL_PANIC_ERROR = 'CANCEL_PANIC_ERROR';

export const PANIC_DONE_REQUEST = 'PANIC_DONE_REQUEST';
export const PANIC_DONE_SUCCESS = 'PANIC_DONE_SUCCESS';
export const PANIC_DONE_ERROR = 'PANIC_DONE_ERROR';

export const GET_PANIC_HISTORY_REQUEST = 'GET_PANIC_HISTORY_REQUEST';
export const GET_PANIC_HISTORY_SUCCESS = 'GET_PANIC_HISTORY_SUCCESS';
export const GET_PANIC_HISTORY_ERROR = 'GET_PANIC_HISTORY_ERROR';

export const GET_ACTIVE_PANIC_HISTORY_REQUEST = 'GET_ACTIVE_PANIC_HISTORY_REQUEST';
export const GET_ACTIVE_PANIC_HISTORY_SUCCESS = 'GET_ACTIVE_PANIC_HISTORY_SUCCESS';
export const GET_ACTIVE_PANIC_HISTORY_ERROR = 'GET_ACTIVE_PANIC_HISTORY_ERROR';

export const GET_WAITING_PANIC_HISTORY_REQUEST = 'GET_WAITING_PANIC_HISTORY_REQUEST';
export const GET_WAITING_PANIC_HISTORY_SUCCESS = 'GET_WAITING_PANIC_HISTORY_SUCCESS';
export const GET_WAITING_PANIC_HISTORY_ERROR = 'GET_WAITING_PANIC_HISTORY_ERROR';

export const TOGGLE_PANIC_DETAILS_MODAL = 'TOGGLE_PANIC_DETAILS_MODAL';
export const REMOVE_PANIC_BY_ID = 'REMOVE_PANIC_BY_ID';

export const ASSIGN_AGENT_REQUEST = 'ASSIGN_AGENT_REQUEST';
export const ASSIGN_AGENT_SUCCESS = 'ASSIGN_AGENT_SUCCESS';
export const ASSIGN_AGENT_ERROR = 'ASSIGN_AGENT_ERROR';

export const DEASSIGN_AGENT_REQUEST = 'DEASSIGN_AGENT_REQUEST';
export const DEASSIGN_AGENT_SUCCESS = 'DEASSIGN_AGENT_SUCCESS';
export const DEASSIGN_AGENT_ERROR = 'DEASSIGN_AGENT_ERROR';

export const GET_PANIC_ROUTE_HISTORY_REQUEST = 'GET_PANIC_ROUTE_HISTORY_REQUEST';
export const GET_PANIC_ROUTE_HISTORY_SUCCESS = 'GET_PANIC_ROUTE_HISTORY_SUCCESS';
export const GET_PANIC_ROUTE_HISTORY_ERROR = 'GET_PANIC_ROUTE_HISTORY_ERROR';
export const CLEAR_PANIC_ROUTE_HISTORY = 'CLEAR_PANIC_ROUTE_HISTORY';

// family actions
export const GET_FAMILY_LIST_REQUEST = 'GET_FAMILY_LIST_REQUEST';
export const GET_FAMILY_LIST_SUCCESS = 'GET_FAMILY_LIST_SUCCESS';
export const GET_FAMILY_LIST_ERROR = 'GET_FAMILY_LIST_ERROR';

export const ADD_FAMILY_REQUEST = 'ADD_FAMILY_REQUEST';
export const ADD_FAMILY_SUCCESS = 'ADD_FAMILY_SUCCESS';
export const ADD_FAMILY_ERROR = 'ADD_FAMILY_ERROR';

export const DELETE_FAMILY_REQUEST = 'DELETE_FAMILY_REQUEST';
export const DELETE_FAMILY_SUCCESS = 'DELETE_FAMILY_SUCCESS';
export const DELETE_FAMILY_ERROR = 'DELETE_FAMILY_ERROR';

export const EDIT_FAMILY_REQUEST = 'EDIT_FAMILY_REQUEST';
export const EDIT_FAMILY_SUCCESS = 'EDIT_FAMILY_SUCCESS';
export const EDIT_FAMILY_ERROR = 'EDIT_FAMILY_ERROR';

export const UPLOAD_AGENT_PHOTO_REQUEST = 'UPLOAD_AGENT_PHOTO_REQUEST';
export const UPLOAD_AGENT_PHOTO_SUCCESS = 'UPLOAD_AGENT_PHOTO_SUCCESS';
export const UPLOAD_AGENT_PHOTO_ERROR = 'UPLOAD_AGENT_PHOTO_ERROR';

export const UPLOAD_TO_STORAGE_REQUEST = 'UPLOAD_TO_STORAGE_REQUEST';
export const UPLOAD_TO_STORAGE_SUCCESS = 'UPLOAD_TO_STORAGE_SUCCESS';
export const UPLOAD_TO_STORAGE_ERROR = 'UPLOAD_TO_STORAGE_ERROR';


export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';

export const UPDATE_AGENT_COORDS = 'UPDATE_AGENT_COORDS';
export const UPDATE_CLIENT_COORDS = 'UPDATE_CLIENT_COORDS';


export const SHOW_2FA_MODAL = 'SHOW_2FA_MODAL';
export const CLOSE_2FA_MODAL = 'CLOSE_2FA_MODAL';

export const CONFIRM_2FA_REQUEST = 'CONFIRM_2FA_REQUEST';
export const CONFIRM_2FA_SUCCESS = 'CONFIRM_2FA_SUCCESS';
export const CONFIRM_2FA_ERROR = 'CONFIRM_2FA_ERROR';

export const SET_LANGUAGE_REQUEST = 'SET_LANGUAGE_REQUEST';
export const SET_LANGUAGE_SUCCESS = 'SET_LANGUAGE_SUCCESS';
export const SET_LANGUAGE_ERROR = 'SET_LANGUAGE_ERROR';

export const TERMINATE_USER_CONTRACT_REQUEST = 'TERMINATE_USER_CONTRACT_REQUEST';
export const TERMINATE_USER_CONTRACT_SUCCESS = 'TERMINATE_USER_CONTRACT_SUCCESS';
export const TERMINATE_USER_CONTRACT_ERROR = 'TERMINATE_USER_CONTRACT_ERROR';

export const AGENT_IN_PLACE_REQUEST = 'AGENT_IN_PLACE_REQUEST';
export const AGENT_IN_PLACE_SUCCESS = 'AGENT_IN_PLACE_SUCCESS';
export const AGENT_IN_PLACE_ERROR = 'AGENT_IN_PLACE_ERROR';

export const HANDLE_IS_OPEN_MODAL = 'HANDLE_IS_OPEN_MODAL';

export const TOGGLE_SHOW_PLANS = 'TOGGLE_SHOW_PLANS';
export const SHOW_PLANS = 'SHOW_PLANS';
export const HIDE_PLANS = 'HIDE_PLANS';

export const TOGGLE_GOOGLE_MAP = 'TOGGLE_GOOGLE_MAP';
export const TOGGLE_GOOGLE_MAP_SUCCESS = 'TOGGLE_GOOGLE_MAP_SUCCESS';
export const TOGGLE_GOOGLE_MAP_ERROR = 'TOGGLE_GOOGLE_MAP_ERROR';

export const TOGGLE_DISABLE_IMAGES = 'TOGGLE_DISABLE_IMAGES';
export const TOGGLE_DISABLE_IMAGES_SUCCESS = 'TOGGLE_DISABLE_IMAGES_SUCCESS';
export const TOGGLE_DISABLE_IMAGES_ERROR = 'TOGGLE_DISABLE_IMAGES_ERROR';

export const SAVE_SOUND_VOLUME = 'SAVE_SOUND_VOLUME';
export const SAVE_SOUND_VOLUME_SUCCESS = 'SAVE_SOUND_VOLUME_SUCCESS';
export const SAVE_SOUND_VOLUME_ERROR = 'SAVE_SOUND_VOLUME_ERROR';

export const GET_USER_SETTINGS_REQUEST = 'GET_USER_SETTINGS_REQUEST';
export const GET_USER_SETTINGS_SUCCESS = 'GET_USER_SETTINGS_SUCCESS';
export const GET_USER_SETTINGS_ERROR = 'GET_USER_SETTINGS_ERROR';

export const SET_SOCKET_READY_STATE = 'SET_SOCKET_READY_STATE';

