import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import ApiMiddleware from './api/middleware';
import { getUserInfoRequest } from 'actions/auth';
import { getUserSettings } from 'actions/settings';
import { LS_TOKEN_KEY } from './constants';

const middleware = ApiMiddleware ? [ApiMiddleware, thunk] : [];

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, {}, composeEnhancers(applyMiddleware(...middleware)));

if (typeof window !== 'undefined') {
  const token = window.localStorage.getItem(LS_TOKEN_KEY);
  if (token) {
    store.dispatch(getUserInfoRequest());
    store.dispatch(getUserSettings());
  }
}

export default store;
