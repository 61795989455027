import React from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { setLanguageRequest, setLocale } from 'actions/translation';
import { IAppState } from 'types';
import { langOptions } from 'constants/index';
import { SupportModal } from 'components/modal';
import { white, lightGray } from 'constants/colors';

const useStyles = makeStyles({
  langLink: {
    color: lightGray,
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    '&:hover': {
      color: white,
    },
  },
  active: {
    color: white,
  },
});

type Props = {
  location?: 'sidebar' | 'header';
}

const LanguageSelect = ({ location = 'header' }: Props) => {
  const dispatch = useDispatch();
  const { active, langLink } = useStyles();

  const isAuthenticated = useSelector(({ auth: { isAuthenticated } }: IAppState) => isAuthenticated);
  const locale = useSelector(({ translation }: IAppState) => translation.locale);

  const changeLang = (lang: string) => {
    if (isAuthenticated) {
      dispatch(setLanguageRequest({ lang }));
    } else {
      dispatch(setLocale(lang));
    }
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      {langOptions.map((lang) => (
        <Box
          onClick={() => changeLang(lang)}
          ml={1.5}
          mr={1.5}
          className={`${langLink} ${locale === lang ? active : ''}`}
          key={lang}
        >
          <Typography variant="subtitle1">{lang}</Typography>
        </Box>
      ))}
      {location === 'header' && (
        <SupportModal />
      )}
    </Box>
  );
};

export default LanguageSelect;
