import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { IntlProvider } from 'react-intl';
import messages from './messages';
import { flattenMessages } from '../utils';

const withIntl = (Component) => {
  const Sub = ({ locale, ...props }) => (
    <IntlProvider locale={locale} messages={flattenMessages(messages[locale])}>
      <Component {...props} />
    </IntlProvider>
  );

  Sub.propTypes = {
    locale: PropTypes.string.isRequired,
  };
  return Sub;
};

withIntl.propTypes = {
  Component: PropTypes.element.isRequired,
};

const mapStateToProps = ({ translation: { locale } }) => ({ locale });

export default compose(connect(mapStateToProps), withIntl);
