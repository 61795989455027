import { Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
  title?: string | JSX.Element;
  open: boolean;
  onConfirm: Function;
  toggleModal: (value?: number) => any;
};

const DeleteModal = ({ title, toggleModal, onConfirm, open }: Props) => {
  const handleConfirm = () => {
    onConfirm();
    toggleModal();
  };
  return (
    <Dialog
      open={open}
      onClose={() => toggleModal()}
      maxWidth="xs"
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title || <FormattedMessage id="common.are_you_sure" defaultMessage="Are you sure?" />}</DialogTitle>
      <DialogActions>
        <Button size="small" onClick={() => toggleModal()} color="secondary" variant="outlined">
          <FormattedMessage id="common.no" defaultMessage="No" />
        </Button>
        <Button size="small" onClick={handleConfirm} variant="outlined" autoFocus>
          <FormattedMessage id="common.yes" defaultMessage="Yes" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteModal;
