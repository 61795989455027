import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { lightGray } from 'constants/colors';

const useStyles = makeStyles({
  cardStyles: {
    marginBottom: '8px',
    padding: '8px 16px',
    boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
  },
  cardHeader: {
    '& p': {
      color: lightGray,
      fontWeight: 'bold',
    },
  },
});

type Props = {
  comment: string;
};

const ClientComment = ({ comment = '-' }: Props) => {
  const { cardStyles, cardHeader } = useStyles();
  return (
    <Box className={cardStyles}>
      <Box mb={2} className={cardHeader}>
        <Typography variant="body2">
          <FormattedMessage id="common.client_comments" />
        </Typography>
      </Box>
      <Typography variant="body2">{comment}</Typography>
    </Box>
  );
};


export default ClientComment;
