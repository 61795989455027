import React from 'react';
import { useStyles } from './styles';
import { Typography } from '@material-ui/core';

type Props = {
  children:  React.ReactNode;
  status: string;
}

const StyledMarkerContent = ({ children, status }:Props) => {
  const { panicMarker, pointer } = useStyles({ status });

  return (
    <div className={panicMarker}>
      <Typography variant="subtitle1">
        {children}
      </Typography>
      <div className={pointer}></div>
    </div>
  );
};


export default StyledMarkerContent;
