import * as c from '../constants/actions';
import { createReducer } from '../utils';

const initialState = {
  notifications: [],
};

export default createReducer(initialState, {
  [c.ENQUEUE_SNACKBAR]: (state, { key, notification }) => ({
    ...state,
    notifications: [
      ...state.notifications,
      {
        key,
        ...notification,
      },
    ],
  }),
  [c.CLOSE_SNACKBAR]: (state, { dismissAll, key }) => ({
    ...state,
    notifications: state.notifications.map((notification) => {
      if (dismissAll || notification.key === key) {
        return { ...notification, dismissed: true };
      }
      return { ...notification };
    }),
  }),
  [c.REMOVE_SNACKBAR]: (state, { key }) => ({
    ...state,
    notifications: state.notifications.filter((notification) => notification.key !== key),
  }),
});
