import React from 'react';
import useStyles from './styles';

export interface CustomCheckboxProps {
  state: boolean;
  onChange: any; // function
}

const CustomCheckbox = ({ state, onChange }: CustomCheckboxProps) => {
  const { checkbox, active } = useStyles();
  return <div className={state ? active : checkbox} onClick={(e) => onChange(e)} />;
};

export default CustomCheckbox;
