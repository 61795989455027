import * as c from '../constants/actions';
import { createReducer } from '../utils';
import { ClientLiveCoords, IClientCoordinatesStoreType } from 'types';

const initialState: IClientCoordinatesStoreType = {
  byId: {},
};


export default createReducer(initialState, {
  [c.UPDATE_CLIENT_COORDS]: (state: IClientCoordinatesStoreType, { payload }: { payload: ClientLiveCoords }) => {
    return {
      ...state,
      byId: { ...state.byId, [payload.clientId]: { lat: payload.lat, lng: payload.lon } },
    }
  }
});
