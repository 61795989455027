import React from 'react';
import { Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from 'types';
import { AdvancedMarker, InfoWindow } from '@vis.gl/react-google-maps';
import { FormattedMessage } from 'react-intl';
import { deassignAgentRequest } from 'actions/panic';
import StyledMarkerContent from '../styled-marker-content';
import { createSelector } from 'reselect';

const AssignedAgentMarker = ({ panicId }: { panicId: number}) => {
  const markerRef = React.useRef(null);
  const [showInfoWindow, setShowInfoWindow] = React.useState(false);
  const dispatch = useDispatch();

  const panicAssignedAgentSelector = createSelector(
    ({ panic }: IAppState) => panic.byId[panicId],
    (panic) => panic.agent
  );

  const agent = useSelector(panicAssignedAgentSelector);
  const agentCoordsSelector = createSelector(
    ({ family }: IAppState) => family.agentsLocation,
    (agentsLocation) => agentsLocation ? agentsLocation[agent.id] : null
  );
  const currentAgentCoords = useSelector(agentCoordsSelector);

  const handleUnassign = () => {
    const params = {
      id: panicId,
      data: {
        agentId: agent.id
      }
    }
    dispatch(deassignAgentRequest({ params, cb: () => setShowInfoWindow(false) }));
  }

  if (!currentAgentCoords) {
    return null
  }
  return (
    <>
      <AdvancedMarker
        position={{ lat: Number(currentAgentCoords.lat), lng: Number(currentAgentCoords.lon) }}
        ref={markerRef}
        onClick={() => {
          setShowInfoWindow((prevState) => !prevState)
        }}
      >
        <StyledMarkerContent status="blue">
          {agent?.fullName}
        </StyledMarkerContent>
      </AdvancedMarker>
      {showInfoWindow && <InfoWindow anchor={markerRef.current} onCloseClick={() => setShowInfoWindow(false)}>
        <Button variant="contained" size='small' onClick={() => handleUnassign()}>
          <FormattedMessage id="common.remove_from_panic" defaultMessage="Remove" />
        </Button>
      </InfoWindow>}
    </>
  );
};

export default AssignedAgentMarker;
