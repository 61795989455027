import React, { ComponentProps } from 'react';
import { Switch as SwitchMUI, makeStyles } from '@material-ui/core';

import { white } from 'constants/colors';

const useStyles = makeStyles({
  thumb: {
    height: 'inherit',
    width: 'inherit',
    backgroundColor: 'inherit',
    border: 'inherit',
    boxShadow: 'inherit',
  },
  track: {
    height: 18,
    borderRadius: 9,
    opacity: '1 !important',
    border: `1px solid ${white}`,
    backgroundColor: 'transparent',
  },
  switchBase: {
    padding: 0,
    height: 18,
    width: 18,
    backgroundColor: 'transparent',
    border: `1px solid ${white}`,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: white,
    },
  },
  root: {
    padding: 0,
    height: 18,
    width: 38,
  },
  checked: {
    backgroundColor: white,
    opacity: '1 !important',
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
});


const CustomSwitch = ({ checked: value, onChange, disabled, name }: ComponentProps<typeof SwitchMUI>) => {
  const { thumb, track, switchBase, root, checked } = useStyles();

  return (
    <SwitchMUI
      disabled={disabled}
      checked={Boolean(value)}
      onChange={onChange}
      name={name}
      classes={{ thumb, track, switchBase, root, checked }}
    />
  );
};

export default CustomSwitch;
